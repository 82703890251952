/* Scrollbar on Firefox */
* {
    scrollbar-width: thin;
    scrollbar-color: rgba(100, 116, 139, .4) rgba(100, 116, 139, .15);
}

/* Scrollbar on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
    width: 0.375rem !important;
    background-color: transparent;
}

*::-webkit-scrollbar-track {
    background-color: rgba(100, 116, 139, .15) !important;
    border-radius: 0.25rem!important;
}

*::-webkit-scrollbar-thumb {
    background-color: rgba(100, 116, 139, .4) !important;
    border-radius: 0.25rem !important;
}


div.conv-list {
    scrollbar-color: rgba(100, 116, 139, 0) rgba(100, 116, 139, 0);
    scrollbar-width: none;
}

div.conv-list::-webkit-scrollbar {
    width: 1px !important;
    background-color: transparent;
}

div.conv-list::-webkit-scrollbar-track {
    background-color: rgba(100, 116, 139, 0) !important;
}

div.conv-list::-webkit-scrollbar-thumb {
    background-color: rgba(100, 116, 139, 0) !important;
}

.no-scrollbar::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}