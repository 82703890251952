.react-datepicker{
    width: 100%;
    border: 1px solid #CFCAB8;
    border-radius: 8px;
    overflow: hidden;
    font-family: 'InterNormal', 'rawline';
}

.react-datepicker__day-name, .react-datepicker__day, .react-datepicker__time-name{
    margin: 6px;
}

.react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range, .react-datepicker__month-text--selected, .react-datepicker__month-text--in-selecting-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--selected, .react-datepicker__quarter-text--in-selecting-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--selected, .react-datepicker__year-text--in-selecting-range, .react-datepicker__year-text--in-range{
    background-color: #002733;
    border-radius: 50%;
}

.react-datepicker__day--keyboard-selected, .react-datepicker__month-text--keyboard-selected, .react-datepicker__quarter-text--keyboard-selected, .react-datepicker__year-text--keyboard-selected{
    background-color: rgba(0, 39, 51, 0.2);
    border-radius: 50%;
}

.react-datepicker__day:hover, .react-datepicker__month-text:hover, .react-datepicker__quarter-text:hover, .react-datepicker__year-text:hover{
    background-color: #002733;
    border-radius: 50%;
    color: #fff;
}
.react-datepicker__header{
    background-color: #fff;
}
.react-datepicker__day-name, .react-datepicker__day, .react-datepicker__time-name{
    text-transform: uppercase;
}
.react-datepicker__current-month, .react-datepicker-time__header, .react-datepicker-year-header{
    text-transform: uppercase;
    letter-spacing: 0.1em;
    margin: 10px 0;
    font-family: "InterMedium";
}
.react-datepicker__navigation-icon{
    top:14px
}
.react-datepicker__month-container{
    float: none;
}

.react-datepicker__year-read-view--down-arrow, .react-datepicker__month-read-view--down-arrow, .react-datepicker__month-year-read-view--down-arrow, .react-datepicker__navigation-icon:before {
    border: 1px solid #CFCAB8;
    border-width: 1px 1px 0 0;
}

.react-datepicker__year-select, .react-datepicker__year-select:focus-visible, .react-datepicker__month-select, .react-datepicker__month-select:focus-visible{
    outline: none;
    background: #fff;
}

.react-datepicker__month-select, .react-datepicker__month-select:focus, .react-datepicker__year-select, .react-datepicker__year-select:focus {
    margin: 0;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-appearance: none;
    -moz-appearance: none;

    border: 1px solid #CFCAB8;
    border-radius: 7px;
    padding: 2px 22px 2px 10px;
    min-width: 110px;
    text-transform: capitalize;

    background-image: url("data:image/svg+xml, %3Csvg width='17' height='10' viewBox='0 0 17 10' fill='none' xmlns='http://www.w3.org/2000/svg' %3E%3Cpath d='M15.5 1.5L8.08823 8.5L1.5 1.5' stroke='%23CFCAB8' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' /%3E%3C/svg%3E");background-repeat: no-repeat;
    background-size: 13px 7px;
    background-position: top 50% right 6px;
}