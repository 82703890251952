/************
	Customizations for Cookie Banner
	loaded with hu-manity.co/hu-banner.min.js
	by Den
***********/
#hu-cookies-content{
  overflow-y: auto;
}
#hu {
  margin: 0 auto !important;
  background: transparent !important;

  .hu-content{
    overflow-y: auto!important;
  }

  .hu-btn {
    border-radius: 35px!important;
    font-family: 'InterBold' !important;
    left: 14%!important;

    &#hu-cookies-save {
      text-transform: uppercase;
      border-radius: 5px !important;
      font-size: 16px !important;
    }
  }

  #hu-outer-wrapper {
    //border-radius: 20px;
    height: 100%;
    background: #FFFEFB !important;

    .hu-heading.hu-text-heading > span {
      font-family: 'InterMedium' !important;
      font-size: 30px;
      font-weight: 500;
      color: #002733;
    }

    #hu-top {
      display: none!important;
    }

    #hu-background #hu-bg-pattern {
      display: none;
    }

    #hu-cookies-notice-about {
      display: none;
    }

    #hu-policy-links {
      display: none;
    }

    .hu-text-body {
      font-size: 18px;
      color: #002733;
      font-family: 'InterNormal', 'rawline';

      p, a {
        font-size: 18px;
        font-family: 'InterNormal', 'rawline';
      }

      strong{
        font-family: 'InterMedium', 'rawline';
      }

      a {
        text-decoration: underline;
        @media (max-width: 699px) {
          font-size: 18px !important;
        }

        @media (max-width: 1199px) {
          font-size: 14px;
        }
      }

      .hu-text-col-wrap {
        text-align: left;
        margin-top: 30px;
        padding-top: 30px;
        border-top: #000 solid;
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        flex-wrap: wrap;

        @media (max-width: 699px) {
          margin-top: 0;
          border-top: 0;
          padding-top: 10px;
          padding-bottom: 60px;
        }

        .hu-text-logo {
          position: absolute;
          left: 0px;
          top: 37px;

          @media (max-width: 699px) {
            display: none;
          }
        }

        .hu-text-col-1 {
          width: calc(40% - 20px);

          @media (max-width: 699px) {
            width: 100%;
          }
        }

        .hu-text-col-2 {
          width: calc(60% - 20px);

          @media (max-width: 699px) {
            width: 100%;
          }
          p {
            @media (max-width: 699px) {
              margin-top: 25px !important;
            }
          }
        }

        .hu-text-col-1, .hu-text-col-2 {
          p {
            margin-bottom: 20px !important;
          }
        }
      }
    }

    #hu-cookies-notice-consent {
      display: none!important;
    }

    #hu-cookies-notice-consent-choices .hu-radio-toggle {
      &.hu-cookies-notice-consent-choices-1 .hu-toggle-switch {
        background-color: #ffbbf4;
      }

      &.hu-cookies-notice-consent-choices-2 .hu-toggle-switch {
        background-color: #ffd86c;
      }

      &.hu-cookies-notice-consent-choices-3 .hu-toggle-switch {
        background-color: #85aeff;
      }
    }
  }

  .hu-text-heading {
    text-align: left!important;
  }

  &.hu-blockui {
    box-shadow: 0 0 0 999em rgba(0, 0, 0, .5), 0 0 50px rgba(0, 0, 0, .15)!important;
  }

  &.hu-blockui:before {
    cursor: default!important;
  }

  #hu-legal-links.hu-links .hu-nav-item {
    font-size: 18px;
    @media (max-width: 576px) {
      margin-bottom: 15px;
    }
  }

  @media (min-width: 769px) {
    .hu-footer {
      padding: 10px 10px 30px!important;
    }
  }
}

body.hu-level-1.hu-cookies-accepted #hu #hu-cookies-privacy-consent-metrics .hu-table-row-3 td:nth-child(2) span:before {
  background-color: #ffbbf4;
}

body.hu-level-2.hu-cookies-accepted #hu #hu-cookies-privacy-consent-metrics .hu-table-row-3 td:nth-child(2) span:before {
  background-color: #ffd86c;
}

body.hu-level-3.hu-cookies-accepted #hu #hu-cookies-privacy-consent-metrics .hu-table-row-3 td:nth-child(2) span:before {
  background-color: #85aeff;
}

body.hu-visible #hu {
  width: 100% !important;
  left: 0 !important;
  right: 0;
  max-width: none !important;
  margin: 0 !important;

  .hu-footer-content > .hu-footer-links {
    width: 30%;
    left: 51%;
    bottom: 25px;

    @media (max-width: 576px) {
      bottom: 70px !important;
    }

    @media (max-width: 699px) {
      left: calc(50% - 102px) !important;
      bottom: 80px;
    }

    @media (max-width: 1000px) {
      left: 55%;
    }
  }

  .hu-footer-content > .hu-footer-actions {
    justify-content: flex-start !important;

    .hu-btn {
      position: relative;
      left: 0!important;
      letter-spacing: .25px;
      width: 100%;
      top: 5px !important;
      padding: 10px 25px!important;
      @media (min-width: 700px) {
        left: 20%!important;
        width: auto!important;
      }
      @media (min-width: 768px) {
        top: -15px !important;
      }
      @media (min-width: 999px) {
        left: 14%!important;
      }
      @media (min-width: 1200px) {
        padding: 24px 75px !important;
        font-size: 20px;
      }
    }
  }
}

body.hu-visible #hu:not(.hu-expanded) {
  height: 50%;
  //background-color: rgba(255, 255, 255, .9) !important;
  //border-radius: 20px;

  @media (min-width: 577px) and (max-width: 699px) {
    min-height: 530px;
  }

  @media (min-width: 700px) {
    min-height: 450px;
  }

  .hu-wrapper {
    height: 100%;

    .hu-inner {
      height: 100%;
    }

    .hu-nav-content:not(.hu-p).hu-active {
      height: 100%;
      min-height: 300px;

      #hu-cookies-head {
        height: 100%;
      }
    }
  }
}

#hu:not(.hu-expanded) #hu-outer-wrapper {
  #hu-cookies-notice-consent-duration {
    display: none;
  }

  #hu-cookies-notice-consent-choices {
    margin-top: 0;
    position: absolute;
    left: calc(50% - 185px);
    z-index: 3;
    top: 10px;

    .hu-cookies-notice-consent-choices-1, .hu-cookies-notice-consent-choices-2, .hu-cookies-notice-consent-choices-3 {
      display: none;
    }
  }

  #hu-cookies-notice-consent-choices .hu-radio-toggle .hu-toggle-heading {
    color: #002733 !important;
    background-color: transparent;
    box-shadow: none;
    cursor: default!important;
  }

  #hu-cookies-notice #hu-cookies-notice-message {
    padding-top: 35px;
    padding-left: 15%;
    padding-right: 0;

    @media (max-width: 1000px) {
      padding-left: 21%;
    }

    @media (max-width: 699px) {
      padding-left: 0;
    }
  }
}

//#hu-cookies-head {
//	display: none!important;
//}

@media (max-width: 1199px) {
  #hu:not(.hu-expanded) {

    #hu-outer-wrapper {
      #hu-cookies-notice #hu-cookies-notice-message .hu-heading {
        margin-bottom: 0;

        .hu-text-container:last-child {
          padding: 10px;
        }
      }

      .hu-text-body {
        font-size: 14px !important;
        padding-bottom: 15px;
      }

      #hu-cookies-notice-consent-choices {
        top: -15px !important;
      }
    }

    .hu-footer-content > .hu-footer-actions {
      .hu-btn {
        top: -15px !important;
      }
    }

    #hu-outer-wrapper .hu-text-body .hu-text-col-wrap .hu-text-logo {
      left: 0;
    }
  }
}

@media (max-width: 768px) {
  #hu:not(.hu-expanded) #hu-outer-wrapper {

    #hu-cookies-notice-consent-choices {
      top: -20px !important;
    }

    .hu-footer-content > .hu-footer-actions .hu-btn {
      top: -15px !important;
    }

    .hu-footer {
      padding-top: 0 !important;
    }
  }

  #hu:not(.hu-expanded) #hu-outer-wrapper .hu-footer-content,
  #hu:not(.hu-expanded) #hu-outer-wrapper .hu-footer-actions {
    transform: translateY(0);
  }
}

@media (max-width: 576px) {
  #hu:not(.hu-expanded) #hu-outer-wrapper {

    #hu-cookies-notice-consent-choices {
      top: -15px !important;
      left: 0 !important;
    }
  }

  #hu #hu-outer-wrapper {
    #hu-cookies-notice-consent .hu-radio-toggle .hu-toggle-label {
      display: inline-block;
    }
    .hu-footer {
      padding-top: 12px!important;
    }
  }
}

@media (min-width: 577px) and (max-width: 768px) {
  #hu:not(.hu-expanded) #hu-outer-wrapper .hu-footer-content > .hu-footer-actions .hu-btn {
    top: -6px;
  }
}

@media (max-width: 699px) {
  #hu:not(.hu-expanded) #hu-outer-wrapper .hu-text-body {
    font-size: 18px !important;
  }

  #hu.hu-expanded #hu-outer-wrapper .hu-footer {
    padding-top: 80px !important;
  }
}

@media (max-width: 480px) {
  body.hu-visible #hu:not(.hu-expanded) {

    #hu-outer-wrapper {
      .hu-wrapper {
        padding-left: 20px;
        padding-right: 20px;
      }

      .hu-text-body {
        font-size: 14px;
      }
    }
  }
}

// Cookie icon not to float:
body.hu-hidden {
  #hu-revoke.hu-position-bottom-left {
    bottom: 75px !important;
  }

  #hu-revoke {
    position: relative !important;
    height: 0 !important;
  }

  .hu-revoke-button {
    cursor: pointer !important;
  }

  @media (max-width: 575px) {
    #hu-revoke.hu-position-bottom-left {
      left: 10px !important;
    }
  }
}

// Mobile
@media (max-width: 576px) {
  body.hu-visible #hu:not(.hu-expanded) {
    height: 60%;

    .hu-wrapper .hu-nav-content:not(.hu-p).hu-active {
      padding-bottom: 135px;
    }

    #hu-outer-wrapper .hu-text-body .hu-text-col-wrap {
      padding-bottom: 0;
    }

    .hu-footer {
      position: absolute;
      bottom: 0;
      left: 0;
      padding-left: 20px;
      padding-right: 20px;
    }

    .hu-wrapper .hu-nav-content:not(.hu-p).hu-active #hu-cookies-head {
      overflow-y: scroll;
      overscroll-behavior: contain;
      -webkit-mask-image: linear-gradient(180deg,transparent 0,#000 5% 95%,transparent);
      mask-image: linear-gradient(180deg,transparent 0,#000 5% 95%,transparent);
    }
  }


  #hu #hu-legal-links.hu-links .hu-nav-item.hu-active {
    margin-bottom: 30px!important;
  }
}

@media (max-width: 420px) {
  #hu #hu-outer-wrapper .hu-heading.hu-text-heading>span {
    font-size: 28px;
  }
}