/********** Range Input Styles **********/
/*Range Reset*/
input[type="range"] {
    -webkit-appearance: none;
    appearance: none;
    background: transparent;
    cursor: pointer;
    /*width: 15rem;*/
}

/* Removes default focus */
input[type="range"]:focus {
    outline: none;
}

/***** Chrome, Safari, Opera and Edge Chromium styles *****/
/* slider track */
input[type="range"]::-webkit-slider-runnable-track {
    background-color: #CECABA;
    border-radius: 2px;
    height: 2px;
}

/* slider thumb */
input[type=range]::-webkit-slider-thumb {
    -webkit-appearance: none; /* Override default look */
    appearance: none;
    border: none;

    margin-top: -13px; /* Centers thumb on the track */
    border-radius: 50%;

    /*custom styles*/
    display: block;
    background: #CECABA;
    height: 30px;
    width: 30px;
}

input[type="range"]:focus::-webkit-slider-thumb {
    border: none;
    outline: none;
    /*outline: 3px solid #CECABA;*/
    /*outline-offset: 0.125rem;*/
}

/******** Firefox styles ********/
/* slider track */
input[type="range"]::-moz-range-track {
    background-color: #CECABA;
    border-radius: 2px;
    height: 2px;
}

/* slider thumb */
input[type="range"]::-moz-range-thumb {
    border: none; /*Removes extra border that FF applies*/
    border-radius: 50%;

    /*custom styles*/
    background-color: #CECABA;
    height: 30px;
    width: 30px;
}

input[type="range"]:focus::-moz-range-thumb {
    border: 1px solid #CECABA;
    /*outline: 3px solid #CECABA;*/
    /*outline-offset: 0.125rem;*/
}
